import React from 'react'


const TopHeaderMenu = () => (
    <header id={"topbar"}>
                <div id={"menus-wrapper"} className={"clearfix"}>
                    <a id={"logo"} href={"/"}></a>
                    <nav id={"menu"}>
                        <ul className={"clearfix"}>
                            <li className={"first_list"}><a href={"/"} className={"main_menu_first main_current"}>home</a></li>
                            <li className={"first_list"}><a href={"/recent163"} className={"main_menu_first"}>view all games</a></li>                            
                            <li className={"first_list"}><a href={"/team"} className={"main_menu_first"}>team</a></li>                            
                            <li className={"first_list with_dropdown"}>
                                <a href={"#"} className={"main_menu_first"}>recent</a>
                                <ul>
                                    <li className={"second_list second_list_border"}><a href={"/recent5"} className={"main_menu_second"}>last 5 games</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/recent10"} className={"main_menu_second"}>last 10 games</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/recent15"} className={"main_menu_second"}>last 15 games</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/recent20"} className={"main_menu_second"}>last 20 games</a></li>
                                </ul>
                            </li>
                            <li className={"first_list with_dropdown"}>
                                <a href={"#"} className={"main_menu_first"}>let me see...</a>
                                <ul>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=offLHP"} className={"main_menu_second"}>against LHP</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=offRHP"} className={"main_menu_second"}>against RHP</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=offStarters"} className={"main_menu_second"}>against starters</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=offRelief"} className={"main_menu_second"}>against relief</a></li>
                                    <li className={"second_list second_list_border"}><a href={"#"} className={"main_menu_second"}></a></li>              
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=longest"} className={"main_menu_second"}>by distance</a></li>                      
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=innings"} className={"main_menu_second"}>by innings</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=pitchType"} className={"main_menu_second"}>by pitch type</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=pitchSpeed"} className={"main_menu_second"}>by pitch speed</a></li>                                    
                                    <li className={"second_list second_list_border"}><a href={"#"} className={"main_menu_second"}></a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=grandslams"} className={"main_menu_second"}>grand slams</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=gamewin"} className={"main_menu_second"}>game winning</a></li>
                                    <li className={"second_list second_list_border"}><a href={"/team?viewBy=gametie"} className={"main_menu_second"}>game tieing</a></li>
                                    
                                   
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <nav id={"toolbar"}>
                        <form method={"get"} id={"searchform"} action={"#"}>
                            <a href={"#"} id={"search-button"}><i className={"icon-search"}></i></a>
                            <fieldset>
                                <input type={"text"} name={"s"} id={"s"} />
                            </fieldset>
                        </form>                      
                    </nav>
                </div>
            </header>
)


export default TopHeaderMenu;