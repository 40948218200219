import React from 'react'
import { useSelector, useDispatch} from 'react-redux';
import { HideShowNoHomers } from '../reducers/gamesReducer';
import HomeRun from "./HomeRun";
import Enumerable from '../app/enumerable';
const GameDay = ( {gamenumber, gameday, gameid, gameinfo, playerId, homer,showHomers, gamesANDhomers}) => {
  const dispatch = useDispatch();

  if(playerId == undefined)
        playerId = 0;

   const fetchedHomers = useSelector(state => state.homers.homers);
   var homers = homer === null ? Enumerable.from(fetchedHomers)
          .where(c=> c.gameid == gameid && (c.playjson[0].batter.id == playerId || playerId == 0))
          .orderByDescending(c=> c.playjson[0].playinfo.id)
          .toArray() : homer;
   
   var NoHomersComp = () => {
    return (
        <div homers={"0"}  id={gamenumber} key={"1"} style={{ display: (showHomers ? 'block' : 'none') }}>      
            <div className={"timeline-date"}>
              <span className={"gamenumber"}><a className={"linktogame"} href={"/game" + gamenumber}>Game #{gamenumber}</a>  {gameinfo.title}</span>
              <span className={"gameday"}>{gameday}</span>
            </div>
            <div className={"timeline-nohomers"}>
              <span>No home runs.</span><span id={"hideshow"}><a style={{color:"white"}} href={"#"} onClick={() => dispatch(HideShowNoHomers(showHomers ? false : true))}> { showHomers ? 'Hide' : 'Show' }</a></span>
            </div>
          </div>
          )
   };
   var SoloHomerComp = () => { return (<div key={homer.playid}><HomeRun gamesANDhomers={gamesANDhomers} homer={homer} gameinfo={gameinfo}/></div>)};
   var MultiHomerComp = () => { 
            return (
                <div key={gameid}>
                  <div className={"timeline-date"}>
                    <span className={"gamenumber"}><a  className={"linktogame"} href={"/game" + gamenumber}>Game #{gamenumber}</a>  {gameinfo.title}</span>
                    <span className={"gameday"}>{gameday}</span>
                  </div>
                  { homers.map((homer) =>(<div key={homer.playid}> <HomeRun  gamesANDhomers={gamesANDhomers} homer={homer} gameinfo={gameinfo}/> </div>))}
                </div>
                )
      };

     return ( 
      homers.length == 0 ?  <NoHomersComp/> : (homers.constructor != Array  ?  <SoloHomerComp/>: <MultiHomerComp/>)
     )
}

export default GameDay
