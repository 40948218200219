import React from 'react'
import Enumerable from '../app/enumerable';
import { useSelector } from 'react-redux'


function ordinal_suffix_of(i) {
  let j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}

const HomeRun = ({homer, gameinfo, gamesANDhomers}) => {
  var games = useSelector(state => state.games).games;  
  var homers = useSelector(state => state.homers).homers

  if(gamesANDhomers == undefined)
    gamesANDhomers = Enumerable.from(games)
    .join(homers, 
                pk=> parseInt(pk.gameid),
                fk=> parseInt(fk.gameid),
                (left, right) => ({...left, ...right})).toArray();            
 

 //where gamenumber is less then current game
  var lastHomer = Enumerable.from(gamesANDhomers)
                  .where(c=> parseInt(c.gameid) != parseInt(homer.gameid) && 
                      c.playjson[0].batter.id == homer.playjson[0].batter.id && 
                      parseInt(c.gamenumber) < parseInt(gameinfo.gamenumber)
                    )
                    .orderByDescending(c=> parseInt(c.gamenumber)) 
                    .thenByDescending(c=> parseInt(c.playjson[0].gameinfo.inning.number))
                                       
                    .firstOrDefault();
  
  var lastHitHomerun = 0;
  
  lastHitHomerun = lastHomer === undefined ? 0 : parseInt(gameinfo.gamenumber) - parseInt(lastHomer.gamenumber);    
  

var distance = homer.playjson[0].playinfo.text
                  .substring(homer.playjson[0].playinfo.text.indexOf("(") + 1,homer.playjson[0].playinfo.text.lastIndexOf(")"))
                  .replace("feet","");

var tie = parseInt(homer.playjson[0].gameinfo.score.home) ==  parseInt(homer.playjson[0].gameinfo.score.away);

var goAhead = 
     (homer.playjson[0].gameinfo.inning.type == "Bottom" 
     && parseInt(homer.playjson[0].gameinfo.score.home) > parseInt(homer.playjson[0].gameinfo.score.away)
     && (parseInt(homer.playjson[0].gameinfo.score.home) - parseInt(homer.playjson[0].playinfo.runs) <= parseInt(homer.playjson[0].gameinfo.score.away))           
    ) ||
     (homer.playjson[0].gameinfo.inning.type == "Top" 
     && parseInt(homer.playjson[0].gameinfo.score.home) < parseInt(homer.playjson[0].gameinfo.score.away)
     && (parseInt(homer.playjson[0].gameinfo.score.away) - parseInt(homer.playjson[0].playinfo.runs) <= parseInt(homer.playjson[0].gameinfo.score.home)));

var win = 
  (homer.playjson[0].gameinfo.inning.type == "Bottom"  && parseInt(homer.playjson[0].gameinfo.inning.number) >= 9 
  && parseInt(homer.playjson[0].gameinfo.score.home) > parseInt(homer.playjson[0].gameinfo.score.away)
  && (parseInt(homer.playjson[0].gameinfo.score.home) - parseInt(homer.playjson[0].playinfo.runs) <= parseInt(homer.playjson[0].gameinfo.score.away))           
 ) ||
  (homer.playjson[0].gameinfo.inning.type == "Top"  && parseInt(homer.playjson[0].gameinfo.inning.number) >= 9  
  && parseInt(homer.playjson[0].gameinfo.score.home) < parseInt(homer.playjson[0].gameinfo.score.away)
  && (parseInt(homer.playjson[0].gameinfo.score.away) - parseInt(homer.playjson[0].playinfo.runs) <= parseInt(homer.playjson[0].gameinfo.score.home)));
  
return (
    <div className={"timeline-item"}>
        <div className={"timeline-player-name"}><span title={homer.playjson[0].batter.name}>{homer.playjson[0].batter.name.split(' ')[1]}</span></div>
        <a href={"/player" + homer.playjson[0].batter.id} className={"timeline-player-link"}>
            <img alt={"avatar"} src={"/images/players/" + homer.playjson[0].batter.id + ".png"} />
        </a>
        <a href={"/player" + homer.playjson[0].batter.id} className={"timeline-item-link"}>
            <div className={"timeline-item-content"}>                                  
                <h2>{homer.playjson[0].batter.name} hits {ordinal_suffix_of(homer.playerstats[0].homeruntotalstonow.total)}</h2>
                <h3>{homer.playjson[0].playinfo.text}</h3>
                <div className={"timeline-item-content-inside"}>
                  <div className={"timeline-item-content-left"}>                       
                    <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>
                          <p> {homer.playjson[0].gameinfo.inning.type} {homer.playjson[0].gameinfo.inning.displayValue}</p>
                      </header>
                      <table className={"performance-facts__table"}>
                        <thead>      
                          <tr>
                            <th colSpan={"3"} className={"small-info"}>
                              Pitcher:  {homer.playjson[0].pitcher.name} {homer.playjson[0].pitcher.throws}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={"2"}>
                              <b>Pitch </b>
                              {homer.playjson[0].playinfo.pitchType} {homer.playjson[0].playinfo.pitchVelocity}mph
                            </th>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>
                              <b>Pitch Count</b>          
                            </th>
                            <td></td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                              Balls
                            </th>
                            <td> {homer.playjson[0].playinfo.pitchcount.balls}
                            </td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                              Strikes
                            </th>
                            <td> {homer.playjson[0].playinfo.pitchcount.strikes}
                            </td>
                          </tr>   
                          <tr>
                            <th colSpan={"2"}>
                              <b>Score after HR</b>          
                            </th>
                            <td></td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                            Phillies
                            </th>
                            <td> {homer.playjson[0].gameinfo.inning.type=="Bottom" ? homer.playjson[0].gameinfo.score.home : homer.playjson[0].gameinfo.score.away}
                            </td>
                          </tr>
                          <tr>
                            <td className={"blank-cell"}>
                            </td>
                            <th>
                            {homer.playjson[0].gameinfo.inning.type=="Bottom" ? gameinfo.awayteam : gameinfo.hometeam}
                            
                            </th>
                            <td> {homer.playjson[0].gameinfo.inning.type=="Bottom" ? homer.playjson[0].gameinfo.score.away : homer.playjson[0].gameinfo.score.home}
                            </td>
                          </tr>   
                            <tr className={"thick-row"}>
                            <td colSpan={"3"} className={"small-info"}>  
                              Games Since last: {lastHitHomerun == 0 ?  "FIRST HR!": lastHitHomerun}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                  <div className={"timeline-item-content-right"}>  
                    <section className={"performance-facts"}>
                        <header className={"performance-facts__header"}>Home Run Stats</header>
                          <table className={"performance-facts__table--small small-info homerunwidth"}>
                                        
                          <thead>     
                            <tr>
                              <td colSpan={"4"}>Home Runs</td>             
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th colSpan={"2"}>Last 3 games</th>
                              <td></td>
                              <td>{homer.playerstats[0].recenthomeruns.last3games}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>Last 5 games</th>
                              <td></td>
                              <td>{homer.playerstats[0].recenthomeruns.last5games}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>Last 10 games</th>
                              <td></td>
                              <td>{homer.playerstats[0].recenthomeruns.last10games}</td>
                              <td></td>
                            </tr>
                            
                          </tbody>
                        </table>
                          <table className={"performance-facts__table--small small-info homerunwidth"}>
                          <thead>     
                            <tr>
                              <td colSpan={"4"}>As of this game totals</td>        
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th colSpan={"2"}>Solo HR</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow.solo}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>2 run HR</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow["2run"]}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>3 run HR</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow["3run"]}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th colSpan={"2"}>Grandslam</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow.grandslam}</td>
                              <td></td>
                            </tr>
                              <tr>
                              <th colSpan={"2"}>TOTAL</th>
                              <td></td>
                              <td>{homer.playerstats[0].homeruntotalstonow.total}</td>
                              <td></td>
                            </tr>
                            
                          </tbody>
                        </table>                      
                    </section>
                  </div>
              </div>
            </div>
        </a>
        {
          parseInt(distance) > 400 || homer.playjson[0].playinfo.runs == 4 || goAhead || tie || win ?
          (
            <div className={"timeline-player-count"}>
            <span>
            {parseInt(distance) > 400 ? <img title={"400+ homer (" + distance + "feet!)!"} src={"../images/icon/ball400plus.png"}/> : "" }
            {homer.playjson[0].playinfo.runs   ==4 ?  <img title={"Grand Slam!"} src={"../images/icon/grandslam.png"}/>: "" }
            {goAhead || tie ?  <img title={"Game tieing or ahead homer!"} src={"../images/icon/tiewinning.png"}/> : "" }
            {win ?  <img title={"Game winning homer!"} src={"../images/icon/ringthebell.png"}/>: "" }
            </span>
            </div>
          ) : ""
}
    </div>
   
)
}
export default HomeRun
