import React from 'react'
import Enumerable from '../app/enumerable';
import { useSelector } from 'react-redux'
import Team from './team';


function ordinal_suffix_of(i) {
  let j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}


const TeamStats = ({}) => {
  var games = useSelector(state => state.games).games;  
  var homers = useSelector(state => state.homers).homers

  

 var lastGameNumber =  Enumerable.from(games).orderByDescending(c=> parseInt(c.gamenumber)).firstOrDefault();

  var gamesANDhomers = Enumerable.from(games)
    .join(homers, 
                pk=> pk.gameid,
                fk=> fk.gameid,
                (left, right) => ({...left, ...right})).toArray();            
        
        var teamStats = {};

        teamStats.lastHomer = Enumerable.from(gamesANDhomers)
                    .orderByDescending(c=> parseInt(c.gamenumber))
                    .thenByDescending(c=> parseInt(c.playjson[0].gameinfo.inning.number)).firstOrDefault();
    
        teamStats.last3games = Enumerable.from(gamesANDhomers)
                    .where(c=> parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-3).count();    
                    teamStats.last5games = Enumerable.from(gamesANDhomers)
                    .where(c=> parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-5).count();  
                    teamStats.last10games = Enumerable.from(gamesANDhomers)
                    .where(c=> parseInt(c.gamenumber) > parseInt(lastGameNumber.gamenumber)-10).count();  

        teamStats.total = Enumerable.from(homers).count();
        teamStats.totalRHP = Enumerable.from(homers).where(c=> c.playjson[0].pitcher.throws == "RHP").count();
        teamStats.totalRHPPlayer= Enumerable.from(homers)
                            .where(c=>c.playjson[0].pitcher.throws == "RHP")
                            .groupBy(c=> c.playjson[0].batter.name,
                                element => element,
                            (key, items) => ({key, count:items.count()}
                        )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();

        teamStats.totalLHP = Enumerable.from(homers).where(c=> c.playjson[0].pitcher.throws == "LHP").count();
        teamStats.totalLHPPlayer= Enumerable.from(homers)
                            .where(c=>c.playjson[0].pitcher.throws == "LHP")
                            .groupBy(c=> c.playjson[0].batter.name,
                                element => element,
                            (key, items) => ({key, count:items.count()}
                        )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
       
        teamStats.totalFullCount= Enumerable.from(homers)
                        .where(c=>parseInt(c.playjson[0].playinfo.pitchcount.balls) == 3 && parseInt(c.playjson[0].playinfo.pitchcount.strikes)== 2)
                        .groupBy(c=> c.playjson[0].batter.name,
                            element => element,
                        (key, items) => ({key, count:items.count()}
                    )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();

        teamStats.totalStarters = Enumerable.from(homers).where(c=>  c.playjson[0].pitcher.position == "Starting Pitcher").count();
        teamStats.totalStartersPlayer= Enumerable.from(homers)
                            .where(c=>c.playjson[0].pitcher.position == "Starting Pitcher")
                            .groupBy(c=> c.playjson[0].batter.name,
                                element => element,
                            (key, items) => ({key, count:items.count()}
                        )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
        teamStats.totalRelief = Enumerable.from(homers).where(c=>c.playjson[0].pitcher.position == "Relief Pitcher").count();
        teamStats.totalReliefPlayer= Enumerable.from(homers)
                            .where(c=>c.playjson[0].pitcher.position == "Relief Pitcher")
                            .groupBy(c=> c.playjson[0].batter.name,
                                element => element,
                            (key, items) => ({key, count:items.count()}
                        )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();

        teamStats.totalGoAhead = Enumerable.from(homers)
                            .where(c=> 
                            (
                            (c.playjson[0].gameinfo.inning.type == "Bottom" 
                            && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                            && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                            ) ||
                            (c.playjson[0].gameinfo.inning.type == "Top" 
                            && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                            && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)) )
                            )).count();
                    
        teamStats.gameGoAheadPlayer= Enumerable.from(homers)
                            .where(c=> 
                            (
                            (c.playjson[0].gameinfo.inning.type == "Bottom" 
                            && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                            && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                            ) ||
                            (c.playjson[0].gameinfo.inning.type == "Top" 
                            && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                            && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)) )
                            )) .groupBy(c=> c.playjson[0].batter.name,
                                element => element,
                            (key, items) => ({key, count:items.count()}
                        )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();

        teamStats.gameWinning = Enumerable.from(homers)
                .where(c=>
                    (
                    (c.playjson[0].gameinfo.inning.type == "Bottom" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                    && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                    && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                    ) ||
                    (c.playjson[0].gameinfo.inning.type == "Top" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                    && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                    && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)))
                    )).count();

        teamStats.gameWinningPlayer= Enumerable.from(homers)
            .where(c=>
                (
                (c.playjson[0].gameinfo.inning.type == "Bottom" && c.playjson[0].gameinfo.inning.number == 9 
                && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                ) ||
                (c.playjson[0].gameinfo.inning.type == "Top" && c.playjson[0].gameinfo.inning.number == 9 
                && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)))
                ))
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
   
   
   

        teamStats.totalTieing = Enumerable.from(homers).where(c=>parseInt(c.playjson[0].gameinfo.score.home) ==  parseInt(c.playjson[0].gameinfo.score.away)).count();
        teamStats.gameGameTiePlayer= Enumerable.from(homers)
                .where(c=>parseInt(c.playjson[0].gameinfo.score.home) ==  parseInt(c.playjson[0].gameinfo.score.away))
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                        (key, items) => ({key, count:items.count()}
                        )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();

        teamStats.longestHR = Enumerable.from(homers)
            .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
            .select(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")"))).firstOrDefault();
        teamStats.longestHRPlayer = Enumerable.from(homers)
        .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
        .select(c=> c.playjson[0].batter.name).firstOrDefault();


        var _lastGame = Enumerable.from(games).orderByDescending((c)=> parseInt(c.gamenumber)).firstOrDefault();
        var _lastGameNumber = _lastGame.gamenumber;
        
        var lastLateGameameNumber = Enumerable.from(gamesANDhomers)
        .where(c=> parseInt(c.playjson[0].gameinfo.inning.number) >=7)
        .orderByDescending((c) => parseInt(c.gamenumber))    
        .firstOrDefault().gamenumber;
        
        var last9thGameameNumber = Enumerable.from(gamesANDhomers)
        .where(c=> parseInt(c.playjson[0].gameinfo.inning.number) ==9)
        .orderByDescending((c) => parseInt(c.gamenumber))    
        .firstOrDefault().gamenumber;

        var lastGrandSlamGameGameNumber = Enumerable.from(gamesANDhomers)
        .where(c=> c.playjson[0].playinfo.runs == 4)
        .orderByDescending((c) => parseInt(c.gamenumber))    
                    .firstOrDefault().gamenumber;

        var      last3runGameGameNumber     = Enumerable.from(gamesANDhomers)
        .where(c=> c.playjson[0].playinfo.runs == 3)
        .orderByDescending((c) => parseInt(c.gamenumber))    
                    .firstOrDefault().gamenumber;

                    var      last2runGameGameNumber     = Enumerable.from(gamesANDhomers)
                    .where(c=> c.playjson[0].playinfo.runs == 2)
                    .orderByDescending((c) => parseInt(c.gamenumber))    
                                .firstOrDefault().gamenumber;
        
        var      lastMultiRunHRGameGameNumber     = Enumerable.from(gamesANDhomers)
        .where(c=> parseInt(c.playjson[0].playinfo.runs) >1 )
        .orderByDescending((c) => parseInt(c.gamenumber))    
                    .firstOrDefault().gamenumber;

                    teamStats.GamesSince_GrandSlam  = parseInt(_lastGameNumber)  - parseInt(lastGrandSlamGameGameNumber);

                    teamStats.GamesSince_3run = parseInt(_lastGameNumber)  - parseInt(last3runGameGameNumber);
                    teamStats.GamesSince_2run= parseInt(_lastGameNumber)  - parseInt(last2runGameGameNumber);
                    teamStats.GamesSince_MultiRunHR= parseInt(_lastGameNumber)  - parseInt(lastMultiRunHRGameGameNumber);
                    teamStats.GamesSince_Back2Back="0"
                    teamStats.GamesSince_9thInningHR=parseInt(_lastGameNumber)  - parseInt(last9thGameameNumber);
                    teamStats.GamesSince_LateGameHomer=String(parseInt(_lastGameNumber) -parseInt(lastLateGameameNumber));
                    teamStats.Back2Back = 0;
                    teamStats.Back2BackLastGame = null;
                    var prevGame = 0;
                    var previnning = 0;
                    var prevatbat = 0;
                            var b2bs = [];
                            var totalBack2Back = 0;

      Enumerable.from(gamesANDhomers)
        .orderByDescending((c) => parseInt(c.gamenumber))
        .thenByDescending((c) => parseInt(c.playjson[0].gameinfo.inning.number))
        .thenByDescending((c) => parseInt(c.playjson[0].batter.batorder))
        .forEach(
          (element) => {
           // console.log(element.gamenumber,element.playjson[0].gameinfo.inning.number,element.playjson[0].batter.batorder )

            if(parseInt(prevGame) == parseInt(element.gamenumber) 
              && parseInt(previnning) == parseInt(element.playjson[0].gameinfo.inning.number) 
              && ((element.playjson[0].batter.batorder == prevatbat-1) || (element.playjson[0].batter.batorder == 1 && prevatbat == 9 )))
                {
                  console.log(element.gamenumber,element.playjson[0].gameinfo.inning.number,element.playjson[0].batter.batorder )
                  //console.log(element, prev)
                  
                  teamStats.Back2Back = teamStats.Back2Back + 1;
                  
                  b2bs.push(element);
                }
          
                prevGame = parseInt(element.gamenumber);
                previnning = parseInt(element.playjson[0].gameinfo.inning.number);
                prevatbat = parseInt(element.playjson[0].batter.batorder);
                
                totalBack2Back++;
          });
          teamStats.Back2BackLastGame = b2bs[0];
          console.log(b2bs,teamStats.Back2BackLastGame,totalBack2Back);


   var viewByQuery= Enumerable.from(homers).select(function (c) {
    return { runs: c.playjson[0].playinfo.runs}}).distinct(c=> c.runs).toArray();
    
    viewByQuery.forEach(function (p){
        p.total = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].playinfo.runs) == parseInt(p.runs)).count();
        p.homers = Enumerable.from(homers).where(c=> parseInt(c.playjson[0].playinfo.runs) == parseInt(p.runs)).toArray();
    });

    var mostSolo = Enumerable.from(viewByQuery).where(c=> c.runs == 1).select(c=> c.homers).firstOrDefault();
        mostSolo  = Enumerable.from(mostSolo)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
    
     var  most2hr = Enumerable.from(viewByQuery).where(c=> c.runs == 2).select(c=> c.homers).firstOrDefault();
            most2hr  = Enumerable.from(most2hr)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
            var  most3hr = Enumerable.from(viewByQuery).where(c=> c.runs == 3).select(c=> c.homers).firstOrDefault();
            most3hr  = Enumerable.from(most3hr)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
            var  mostgrandslam = Enumerable.from(viewByQuery).where(c=> c.runs == 4).select(c=> c.homers).firstOrDefault();
            mostgrandslam  = Enumerable.from(mostgrandslam)
                .groupBy(c=> c.playjson[0].batter.name,
                    element => element,
                (key, items) => ({key, count:items.count()}
            )).orderByDescending(c=> parseInt(c.count)).firstOrDefault();
    
            var playerOnlyHomers = Enumerable.from(homers).join(games, 
                pk=> pk.gameid,
                fk=> fk.gameid,
                (left, right) => ({...left, ...right}))
                    .orderByDescending(c=> parseInt(c.gamenumber)) .toArray();
                 
      
                var longestHomerGameHitStreak = 1;
                var currentlongestHomerGameHitStreak = 1;
                var lastgame = 0;
                playerOnlyHomers.map((homerHit, i) => {  
                  
                  if(parseInt(homerHit.gamenumber) == lastgame -1)
                    currentlongestHomerGameHitStreak +=1;
                  else
                  {
                    if (currentlongestHomerGameHitStreak > longestHomerGameHitStreak)
                      longestHomerGameHitStreak  = currentlongestHomerGameHitStreak;
      
                      currentlongestHomerGameHitStreak=1;
                  }
      
                  lastgame=parseInt(homerHit.gamenumber)
      
                });
    
    return (<div>
    <div className={"timeline-item"}>
              <div className={"timeline-player-name"}><span>Team Stats</span></div>
            <a href={"#"} className={"timeline-player-link"}>
                <img alt={"avatar"} src={"/img/philadelphia-phillies-vector-logo.png"} />
            </a>
           
                    
          <div className={"timeline-item-content-team"}>
            
              <h2>{gamesANDhomers.length} total homers in {games.length} games! </h2>
              <h3>Last homerrun was {teamStats.lastHomer.playjson[0].batter.name} on {teamStats.lastHomer.date}</h3>
              <div className={"timeline-item-content-inside"}>
                <div className={"timeline-item-content-left"}>                                       
                  <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>Home Run Stats</header>
                        <table className={"performance-facts__table--small small-info homerunwidth"}>                                      
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Team Totals</td>             
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={"2"}>Last 3 games</th>
                            <td></td>
                            
                            <td>{teamStats.last3games}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last 5 games</th>
                            <td></td>                            
                            <td>{teamStats.last5games}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last 10 games</th>
                            <td></td>                            
                            <td>{teamStats.last10games}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>vs. RHP</th>                            
                            <td></td>
                            <td>{teamStats.totalRHP}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>vs. LHP</th>                            
                            <td></td>
                            <td>{teamStats.totalLHP}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>vs. Starters</th>                            
                            <td></td>
                            <td>{teamStats.totalStarters}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>vs. Relief</th>                            
                            <td></td>
                            <td>{teamStats.totalRelief}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Tieing</th>                            
                            <td></td>
                            <td>{teamStats.totalTieing}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Go Ahead</th>                            
                            <td></td>
                            <td>{teamStats.totalGoAhead}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Game Winning</th>                            
                            <td></td>
                            <td>{teamStats.gameWinning}</td>
                          </tr> 
                          <tr>
                            <th colSpan={"2"}>Longest HR Game Streak</th>                            
                            <td></td>
                            <td>{longestHomerGameHitStreak}</td>
                          </tr> 
                          <tr>
                            <th colSpan={"2"}>Total Back-2-Backs</th>                            
                            <td></td>
                            <td>{teamStats.Back2Back}</td>
                          </tr> 

                          
                        </tbody>
                      </table>
                      </section>
                      <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>Team Leaders</header>
                    <table className={"performance-facts__table--small small-info homerunwidth"}>                      
                        
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Team Leader</td>             
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th colSpan={"2"}>Longest</th>                            
                            <td>{teamStats.longestHR}</td>
                            <td>{teamStats.longestHRPlayer}</td>
                          </tr> 
                          <tr>
                            <th colSpan={"2"}>Most Solo HR</th>                            
                            <td>{mostSolo.count}</td>
                            <td>{mostSolo.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most 2-run HR</th>                            
                            <td>{most2hr.count}</td>
                            <td>{most2hr.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most 3-run HR</th>                            
                            <td>{most3hr.count}</td>
                            <td>{most3hr.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Grand Slams</th>                            
                            <td>{mostgrandslam.count}</td>
                            <td>{mostgrandslam.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Game Winning HR</th>                            
                            <td>{teamStats.gameWinningPlayer.count}</td>
                            <td>{teamStats.gameWinningPlayer.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Go Ahead HR</th>                            
                            <td>{teamStats.gameGoAheadPlayer.count}</td>
                            <td>{teamStats.gameGoAheadPlayer.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Game Tieing HR</th>                            
                            <td>{teamStats.gameGameTiePlayer.count}</td>
                            <td>{teamStats.gameGameTiePlayer.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Off Starters</th>                            
                            <td>{teamStats.totalStartersPlayer.count}</td>
                            <td>{teamStats.totalStartersPlayer.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Off Relief Pitching</th>                            
                            <td>{teamStats.totalReliefPlayer.count}</td>
                            <td>{teamStats.totalReliefPlayer.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Off RHP</th>                            
                            <td>{teamStats.totalRHPPlayer.count}</td>
                            <td>{teamStats.totalRHPPlayer.key}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Most Off LHP</th>                            
                            <td>{teamStats.totalLHPPlayer.count}</td>
                            <td>{teamStats.totalLHPPlayer.key}</td>
                          </tr>

                          <tr>
                            <th colSpan={"2"}>Most With Full Count</th>                            
                            <td>{teamStats.totalFullCount.count}</td>
                            <td>{teamStats.totalFullCount.key}</td>
                          </tr>
                        </tbody>
                      </table>                      
                  </section>

                  <section className={"performance-facts"}>
                      <header className={"performance-facts__header"}>Last Game Since</header>
                    <table className={"performance-facts__table--small small-info homerunwidth"}>                      
                        
                        <thead>     
                          <tr>
                            <td colSpan={"4"} style={{textAlign: 'right'}}>Team Leader</td>             
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th colSpan={"2"}>Last Multi-run HR</th>                            
                            <td></td>
                            <td>{teamStats.GamesSince_MultiRunHR == 0 ? "Last Game!" : teamStats.GamesSince_MultiRunHR}</td>
                          </tr> 
                          <tr>
                            <th colSpan={"2"}>Last Grand Salm</th>                            
                            <td></td>
                            <td>{teamStats.GamesSince_GrandSlam == 0 ? "Last Game!" : teamStats.GamesSince_GrandSlam}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last 3-run HR</th>                            
                            <td></td>
                            <td>{teamStats.GamesSince_3run == 0 ? "Last Game!" : teamStats.GamesSince_3run}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last 2-run HR</th>                            
                            <td></td>
                            <td>{teamStats.GamesSince_2run == 0 ? "Last Game!" : teamStats.GamesSince_2run}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last Late Game (7,8,9) HR</th>                            
                            <td></td>
                            <td>{teamStats.GamesSince_LateGameHomer == 0 ? "Last Game!" : teamStats.GamesSince_LateGameHomer}</td>
                          </tr>
                          <tr>
                            <th colSpan={"2"}>Last 9th Inning HR</th>                            
                            <td></td>
                            <td>{teamStats.GamesSince_9thInningHR == 0 ? "Last Game!" : teamStats.GamesSince_9thInningHR}</td>
                          </tr>

                          

                          
                          
                        </tbody>
                      </table>                      
                  </section>
                </div>
                
                </div>
          </div>
                         
</div>   </div>
)
}
export default TeamStats
