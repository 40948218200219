import React, { useEffect } from 'react'
 
import TopSocialLinks from './TopSocialLinks';
import TopHeaderMenu from "./TopHeaderMenu";
import SideBarPlayers from "./SideBarPlayers";
import PlayerStats from "./playerstats";
import GameList from "./GameList";
import Enumerable from '../app/enumerable';
import { useParams } from "react-router-dom";
import { useSelector} from 'react-redux';

const player = () => { 
    const routeParams = useParams();
    
   var homers = useSelector(state => state.homers.homers);
   var player = Enumerable.from(homers).where(c=> c.playjson[0].batter.id == routeParams.playerId).firstOrDefault();
   var returnPlayer =  {
            id: player.playjson[0].batter.id, 
            batter: player.playjson[0].batter.name,
            photo: player.playjson[0].batter.photo
        };
console.log(homers);
   returnPlayer.total = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==routeParams.playerId).count();
   returnPlayer.totalRHP = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==routeParams.playerId &&  c.playjson[0].pitcher.throws == "RHP").count();
   returnPlayer.totalLHP = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==routeParams.playerId &&  c.playjson[0].pitcher.throws == "LHP").count();
   returnPlayer.totalStarters = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==routeParams.playerId &&  c.playjson[0].pitcher.position == "Starting Pitcher").count();
   returnPlayer.totalRelief = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==routeParams.playerId &&  c.playjson[0].pitcher.position == "Relief Pitcher").count();
   returnPlayer.totalGoAhead = Enumerable.from(homers)
                                .where(c=> c.playjson[0].batter.id ==routeParams.playerId && 
                                    (
                                    (c.playjson[0].gameinfo.inning.type == "Bottom" 
                                    && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                                    && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                                    ) ||
                                    (c.playjson[0].gameinfo.inning.type == "Top" 
                                    && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                                    && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)) )
                                    )).count();
   returnPlayer.gameWinning = Enumerable.from(homers)
                                .where(c=> c.playjson[0].batter.id ==routeParams.playerId && 
                                    (
                                    (c.playjson[0].gameinfo.inning.type == "Bottom" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                                    && parseInt(c.playjson[0].gameinfo.score.home) > parseInt(c.playjson[0].gameinfo.score.away)
                                    && (parseInt(c.playjson[0].gameinfo.score.home) -parseInt(c.playjson[0].playinfo.runs) <parseInt(c.playjson[0].gameinfo.score.away))           
                                    ) ||
                                    (c.playjson[0].gameinfo.inning.type == "Top" && parseInt(c.playjson[0].gameinfo.inning.number) >= 9  
                                    && parseInt(c.playjson[0].gameinfo.score.home) < parseInt(c.playjson[0].gameinfo.score.away)
                                    && (parseInt(c.playjson[0].gameinfo.score.away) -parseInt(c.playjson[0].playinfo.runs) <= parseInt(c.playjson[0].gameinfo.score.home)))
                                    )).count();
   returnPlayer.totalTieing = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==routeParams.playerId &&  parseInt(c.playjson[0].gameinfo.score.home) ==  parseInt(c.playjson[0].gameinfo.score.away)).count();
   returnPlayer.longestHR = Enumerable.from(homers).where(c=> c.playjson[0].batter.id ==routeParams.playerId)
   .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
   .select(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")"))).firstOrDefault();


    return (
        
        <div id={"root"}>
            {gtag('event', 'view_player', {playerId:routeParams.playerId, playerName:player.playjson[0].batter.name} )}
            
              <TopSocialLinks />
              <TopHeaderMenu />
              <div id={"main"}>			
                  <div id={"home"} className={"group"}>
                      <div id={"timeline"} className={"col span_2_of_3"}>     
                      <div className={"timeline-header"}><span>Viewing {player.playjson[0].batter.name}'s home runs!</span></div>      
                      <PlayerStats  player = {returnPlayer}/>                                                                                
                      <GameList playerId = {routeParams.playerId} recentgames={163}/>  
                      </div>
                      <div id={"sidebar"} className={"col span_1_of_3"}>
                          <div className={"widget widget_search"}>                            
                          </div>                       
                          <SideBarPlayers />
                      </div>
                  </div>                
              </div>
              <footer id={"footer"} className={"clearfix"}></footer>
      </div>
  );
}

export default player

