import { createSlice,nanoid, createAsyncThunk ,current } from '@reduxjs/toolkit'


export const fetchGames = createAsyncThunk('games/fetchGames', async () => {
    var returnData = null;
    await fetch('https://www.phillieshomeruns.com/api/app/v1/getAllGames')
    .then(response => response.json())
    .then(data => { 
      console.log("fetched data");      
      returnData = {
        games: data.Games,
        status:'succeeded',
        anyNew: false, 
        lastupdated: dayOfYear(new Date())
    }});     

    return returnData;
  
})


export const checkForNewGames = createAsyncThunk('games/checkForNewGames', async () => {
  var returnData = null;
  await fetch('https://www.phillieshomeruns.com/api/app/v1/getAllGamesCount')
  .then(response => response.json())
  .then(data => {     
    returnData = {
      count: data.Games,  
  }});     

  return returnData;

})


export const HideShowNoHomers = createAsyncThunk('games/HideShowNoHomers', async (hideshow) => {
  
  return hideshow;

})



const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));



export const gamesSlice = createSlice({
  name: 'games',
  initialState: {
    games: [],
    status:'idle',   
    anyNew: "no", 
    lastupdated:0,
    shownohomers: true
  },
  reducers: {   },
  extraReducers(builder) {
    
    builder
      .addCase(fetchGames.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.games = action.payload.games;
        state.lastupdated = action.payload.lastupdated;
        
      })
      .addCase(checkForNewGames.pending, (state, action) => {
        state.anyNew = 'checking'
      })
      .addCase(HideShowNoHomers.fulfilled, (state, action) => {
        
        state.shownohomers = action.payload;
      })

      .addCase(checkForNewGames.fulfilled, (state, action) => {
        var cur_state = current(state);
        if(cur_state.games.length < action.payload.count)
          state.anyNew = "yes";
        else
        state.anyNew = "no"; 
         
      })
     
  }
})

export const { loadAllGames } = gamesSlice.actions


export default gamesSlice.reducer
