import React from 'react'
import Enumerable from '../app/enumerable';
import { useSelector } from 'react-redux'


const SideBarPlayers = () => {
    var homers = useSelector(state => state.homers.homers);
    var groupedList = Enumerable.from(homers).select(function (c) {
        return {  id: c.playjson[0].batter.id, 
         batter: c.playjson[0].batter.name,
         photo: c.playjson[0].batter.photo}}).distinct(c=> c.id).toArray();
    return (
                        <div className={"widget widget-social"}>
                            <div><span>
                            <h3 className={"widget-title"}>What's it mean?</h3>
                                <img height={40} width={40} title={"400+ homer"} src={"../images/icon/ball400plus.png"}/> 400+ foot homerun<br/><br/> 
                                <img height={40} width={40} title={"Grand Slam"} src={"../images/icon/grandslam.png"}/> Grand Slam homerun<br/><br/> 
                                <img height={40} width={40} title={"Game tieing or ahead homer!"} src={"../images/icon/tiewinning.png"}/> Game tieing or ahead homer!<br/><br/> 
                                <img height={40} width={40} title={"Game winning homer!"} src={"../images/icon/ringthebell.png"}/> Game winning homer!<br/>             <br/>        
                            </span></div><br/> <br/> 
                            <h3 className={"widget-title"}>Players</h3>
                            <ul className={"social-buttons-list clearfix"}>
                            { Enumerable.from(groupedList).orderByDescending(c=> parseInt(c.batter)).toArray().map((player) => (
                                <li key={player.id}><a className={"social-button"} href={"/player" + player.id}>
                                   
                                    <img src={"/images/players/" + player.id + ".png"} style={{height:"85px", width:"90px"}}/></a>
                                  </li>                      
                                )) 
                            }      
                              
                            </ul>
                        </div>)
}

export default SideBarPlayers
