import { createSlice,nanoid, createAsyncThunk  } from '@reduxjs/toolkit'

export const fetchHomers = createAsyncThunk('games/fetchHomers', async () => {
  
  var returnData = null;
    await fetch('https://www.phillieshomeruns.com/api/app/v1/getAllHomers')
    .then(response => response.json())
    .then(data => { 
      console.log("fetched homers");
      returnData = {
        homers: data.Games,
        status:'succeeded'
    }});     

    return returnData;
  
})

export const homersSlice = createSlice({
  name: 'homers',
  initialState: {
    homer: [],
    status:'idle'    
  },
  reducers: {    },
  extraReducers(builder) {
    
    builder
      .addCase(fetchHomers.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchHomers.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.homers = action.payload.homers;
        console.log("action",action,state);
      })
     
  }
})

export const { loadAllGames } = homersSlice.actions


export default homersSlice.reducer


