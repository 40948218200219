import React from 'react'


const TopSocialLinks = () => (
    <div id="hero" className="clearfix">
    <div id="global-social">
        <a className="global-facebook" href="https://www.facebook.com/Phillies">
            <i className="icon-facebook"></i><span>facebook</span>
        </a>
        <a className="global-twitter" href="https://twitter.com/Phillies">
            <i className="icon-twitter"></i><span>twitter</span>
        </a>
        <a className="global-instagram" href="https://www.instagram.com/phillies">
            <i className="icon-instagram"></i><span>instagram</span>
        </a>
    </div>
</div>
)


export default TopSocialLinks;