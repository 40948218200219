import { createSlice } from '@reduxjs/toolkit'

export const playersSlice = createSlice({
  name: 'players',
  initialState: {
    players: [],
  },
  reducers: {
    loadAllPlayers: (state) => {
      state.value -= 1
    },
  },
})

export const { loadAllPlayers } = playersSlice.actions


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.players.value)`
export const selectCount = (state) => state.players.length

export default playersSlice.reducer
