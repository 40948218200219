import React from 'react'
import { useSelector } from 'react-redux';
import Enumerable from '../app/enumerable';
import GameDay from './GameDay';

const GameList = ({playerId, gameid, recentgames, showHomers}) => {
    const fetchStatus = useSelector(state => state.games.status);
    const sort  =location.search.includes("distance");    
  
    const homers = useSelector(state => state.homers.homers);
    const games = useSelector(state => state.games.games);
    
    var gamesToDisplay;
    var gamesANDhomers = Enumerable.from(games)
    .join(homers, 
      pk=> pk.gameid,
      fk=> fk.gameid,
      (left, right) => ({...left, ...right})).toArray();  

    if(sort){   
      gamesToDisplay = Enumerable.from(gamesANDhomers)
                        .where(c=> (c.playjson[0].batter.id == playerId || playerId == undefined) && (gameid == undefined || c.gamenumber == gameid))
                        .orderByDescending(c=> c.playjson[0].playinfo.text.substring(c.playjson[0].playinfo.text.indexOf("(") + 1,c.playjson[0].playinfo.text.lastIndexOf(")")))
                        .toArray();

      
    } else {
      gamesToDisplay =  Enumerable.from(games)
      .where(c=> gameid == undefined || c.gamenumber == gameid)
      .orderByDescending(c=> sort ? parseInt(c.gamenumber) : parseInt(c.gamenumber))
      .take(recentgames == null ? 10 : recentgames)
      .toArray();
    }
    
   return (    
    <div>
         {     
              fetchStatus == "loading" ? "LOADING GAME DATA" :
         games != undefined ? 
         gamesToDisplay.map((game) => (
            <GameDay 
              gamesANDhomers = {gamesANDhomers}
              showHomers = {showHomers}
              key={game.gameid + game.playid}
              gameinfo={game} 
              gameid = {game.gameid}
              gamenumber={game.gamenumber} 
              gameday={game.date} 
              playerId={playerId}
              homer ={sort ? game : null}
              sort={sort ? "distance" : ""}
            />
            )) : ""
        }
      
    </div>
)
}

export default GameList
