import React, { useEffect } from 'react'
import { useSelector, useDispatch} from 'react-redux';

import { fetchGames , checkForNewGames } from '../reducers/gamesReducer';
import { fetchHomers  } from '../reducers/homersReducer';

const CheckCache = () => {

  const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));
    const dispatch = useDispatch();
    const fetchStatus = useSelector(state => state.games.status);
    const fetchStatusNewGames = useSelector(state => state.games.anyNew);
    const lastupdated = useSelector(state => state.games.lastupdated);
    const currentDay = dayOfYear(new Date());
    
    
    let games = useSelector(state => state.games.games);
   
    useEffect(() => {
      dispatch(checkForNewGames());
        if ( fetchStatus == 'idle' || fetchStatusNewGames == "yes" || (currentDay>lastupdated && fetchStatus == 'succeeded')) {          
          dispatch(fetchGames());          
          dispatch(fetchHomers());
        }
      }, [fetchStatus, games, dispatch]);
    
    
   return (null)
}

export default CheckCache
